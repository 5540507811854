export const modules = [
  {
    number: "1",
    name: "Teamcenter UA User/Functional",
    items: [
      "Introduction to Teamcenter",
      "My Teamcenter",
      "Structure Manager",
      "Working with Workflow and Workflow Viewer",
      "Projects",
      "Applying Securities",
      "Search",
      "JT Viewer",
    ],
  },
  {
    number: "2",
    name: "Teamcenter UA Application Administration",
    items: [
      "Organization",
      "Workflow Designer",
      "Access Manager",
      "Project Admin",
      "Managing Preferences",
      "Query Builder",
      "Report Builder",
      "PLMXML Import-Export",
      "Utilities",
    ],
  },
  {
    number: "3",
    name: "Teamcenter UA Data Model Administration",
    items: [
      "Introduction to BMIDE",
      "Introduction to Data Model",
      "Various object creation- Item, Datasets, Form, Relations etc…",
      "LOVs- Classic, Filter, Cascading, Interdependent, Dynamic, Batch LOV",
      "Rules: Naming Rules, Revision Naming Rule, Display Rule, Deep Copy Rule, GRM Rule",
      "Operation Descriptor",
      "BMIDE Reports",
      "Data Model Live Update",
      "Hot Deployment",
      "Cold Deployment",
      "Etc…",
    ],
  },
  {
    number: "4",
    name: "Teamcenter Deployment/Installation ",
    items: [
      "Pre-requisite Installation",
      "Database Installation (Oracle/MSSQL)",
      "Database Creation",
      "Teamcenter Server Installation",
      "Web-Tier Installation (IIS or JBoss)",
      "Client Installation 2T, 4T",
      "Deployment Center",
    ],
  },
  {
    number: "5",
    name: "Teamcenter Server Side Customization (ITK)",
    items: [
      "Basic Server Customization",
      "Basic concept of ITK Customization",
      "Detail discussion on modules in ITK",
      "Syntax Convention Used",
      "Format",
      "Variable naming Conventions",
      "Class Hierarchy",
      "Include Files",
      "Special Data Types",
      "Basic tasks for ITK customization",
      "Configure Visual Studio to build and debug ITK applications",
      "Error handling",
      "Memory management",
      "Batch ITK",
      "Custom exits",
      "Handler customization",
      "Introduction to data-model-based customization",
      "Configure BMIDE to build data model libraries",
      "Data Model Customization Type",
    ],
  },
  {
    number: "6",
    name: "Teamcenter Service Oriented Architecture (SOA)",
    items: [
      "Client vs Server Service oriented Programming",
      "SOA Architecture",
      "SOA Fundamentals and Components",
      "WSDL",
      "SOA Vs AWC",
      "SOA Service",
      "Data Types and Operations",
      "Service versions and API help understanding",
      "RAC-SOA vs Loose SOA vs Strong SOA",
      "Using Existing SOA based API",
      "Writing/Implementing custom SOA API",
      "Calling API through client",
    ],
  },
  {
    number: "7",
    name: "Teamcenter UA NX CAD Integration",
    items: [
      "NX Installation",
      "NX integration with Teamcenter",
      "NX Template import",
      "Attribute Mapping",
      "Search parts from NX Manager",
      "Create Part, Drawing and Assembly",
      "Edit Part, Drawing and Assembly",
      "Revise and Save As Part, Drawing and Assembly",
    ],
  },
  {
    number: "8",
    name: "Teamcenter AWC User Level",
    items: [
      "Active Workspace Overview",
      "Active Workspace Architecture",
      "Home View",
      "Tile Overview",
      "Report Overview",
      "Project Overview",
      "Preferences Overview",
      "Display Data in Table, List, Tree View",
      "Modify Sort Order in Table View",
      "Attach Dataset to Item Revision",
      "Profile Page Overview",
      "Access Manager View in AWC",
      "Work Area",
      "Creation of objects (Item, Dataset, Folder, Form, etc.)",
      "Save as and Revise objects",
      "Basic cut, copy, paste, delete etc. operation",
      "Preview/Viewer in AWC",
      "Different Commands and location sub location",
      "Location And Sub location",
      "Change Theme Session And Context",
      "Search in AWC",
      "Structure Manager",
    ],
  },
  {
    number: "9",
    name: "Teamcenter AWC Administration",
    items: [
      "Active workspace Indexing",
      "BMIDE Configuration",
      "Column management and UI configuration",
      "Hidden Command",
      "Pre-filter settings for search category",
      "Static & Dynamic Tile Creation",
      "Report in Active workspace",
      "Workflow Designer in Active workspace",
      "Audit Report in AWC",
      "Social Collaboration in AWC",
      "Preferences Management in AWC",
      "Project in AWC",
      "Active Workspace Installation",
    ],
  },
  {
    number: "10",
    name: "Teamcenter AWC Customization ",
    items: [
      "AWC Style sheet Customization",
      "Custom Icon for custom Business Object in AWC",
      "Icon Rendering in AWC",
      "AWC Login page Background Image Change",
      "Change Logo in AWC",
      "Overview of Build Command",
      "Set AWC Development Environment",
      "SOA Customization",
      "Create One-step Command",
      "Customization of Tools and Info Command",
      "Customization of Location and Sub location",
    ],
  },
];
